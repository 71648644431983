<template>
  <div class="clerical">
    <div class="search-top flex-between">
      <div></div>
      <div class="search-but">
        <el-button @click="addClerical" class="color-vice" type="primary">
          新增
        </el-button>
      </div>
    </div>
    <div class="clerical-cont">
      <div class="clerical-item" v-for="item in clericalList" :key="item.id">
        <div class="flex-between">
          <div class="_left flex-start">
            <div class="_item flex-start">
              <div class="_lable">日期：</div>
              <div class="cont">{{ item.sign_time }}</div>
            </div>
            <div class="_item flex-start">
              <div class="_lable">文书名称：</div>
              <div class="cont">
                {{ gitDictVal(250000000, item.dict_sign_type) }}
              </div>
            </div>
          </div>
          <div class="butBox flex-center">
            <el-button
              class="color-vice"
              type="primary"
              @click="editCoures(item)"
            >
              <i class="fa fa-edit"></i>修改
            </el-button>
            <el-button
              class="color-red"
              @click="delCoures(item)"
              :loading="delLoading"
              type="primary"
            >
              <i class="fa fa-trash-o"></i>删除
            </el-button>
          </div>
        </div>
        <div class="imgBox">
          <img
            v-for="(item2, index2) in item.paper_list"
            :key="index2"
            class="_img"
            :src="item2.url"
          />
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <BaseDialog
      :title="ruleForm.id ? '修改文书' : '新增文书'"
      :isshow="modCoures"
      @handleShow="
        (val) => {
          modCoures = val
        }
      "
      width="800px"
      height="auto"
    >
      <div class="schemeBox">
        <div class="userItem">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="Rule"
            :inline="true"
            label-width="84px"
            label-position="right"
            class="demo-ruleForm col-333"
          >
            <el-form-item label="拍照日期" prop="sign_time">
              <el-date-picker
                v-model="ruleForm.sign_time"
                type="datetime"
                value-format="YYYY-MM-DD HH:mm:ss"
                placeholder="请选择拍照日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="文书案例" prop="dict_sign_type">
              <el-select v-model="ruleForm.dict_sign_type" placeholder="请选择">
                <el-option
                  v-for="item in dictList[250000000]"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="addr" label="文书图片">
              <el-upload
                action=""
                ref="uploadimg"
                :class="{ hide: hideUpload }"
                :http-request="handleRequest"
                list-type="picture-card"
                :on-change="handleEditChange"
                v-model:file-list="imgList"
                :limit="5"
              >
                <template #default>
                  <i class="fa fa-plus"></i>
                </template>
                <template #file="{ file }">
                  <div style="height: 100%">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handleRemove(file)"
                      >
                        <i class="fa fa-trash-o"></i>
                      </span>
                    </span>
                  </div>
                </template>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="modCoures = false"
          >
            取消
          </el-button>
          <el-button
            @click="saveCoures"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick, defineExpose } from 'vue'
import { useRoute } from 'vue-router'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import BaseDialog from '@/components/Dialog/index.vue'
import { gitDictList } from '@/utils/tool.js'
import comm from '@/utils/comm'
let port = location.protocol
        let ip = comm.cesuUrl
        let requestUrl = `${port}//${ip}/`
const route = useRoute()
const searchData = reactive({
  patient_id: route.params.id,
  current: 1,
  size: 10,
})
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const ruleForm = ref({})
const clericalList = ref([])
const dictList = ref([])
const modCoures = ref(false)
const ruleFormRef = ref(null)
const uploadimg = ref(null)
const imgList = ref([])
const hideUpload = ref(false)
const Rule = reactive({
  sign_time: [{ required: true, message: '请选择拍照日期', trigger: 'change' }],
  dict_sign_type: [
    { required: true, message: '请选择文书案例', trigger: 'change' },
  ],
})

const initData = () => {
  getDict()
  handleSearch()
}
const getDict = async () => {
  const dictLists = await gitDictList(['250000000'])
  dictList.value = {
    ...dictList.value,
    ...dictLists,
  }
  console.log(dictList.value)
}
const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}

const handleCurrentChange = (val) => {
  pageData.currentPage = val
  getDataList()
}
const handleSearch = () => {
  pageData.currentPage = 1
  getDataList()
}
const getDataList = async () => {
  searchData.current = pageData.currentPage
  searchData.size = pageData.pageSize
  let res = await service.post(
    '/api/patient_paper/list_patient_paper',
    searchData
  )
  if (res.code === 0) {
    clericalList.value = res.data.records
    pageData.totalSum = res.data.total
  }
}

const addClerical = () => {
  modCoures.value = true
  ruleForm.value = {}
  ruleForm.value.paper_list = []
  imgList.value = []
  nextTick(() => {
    ruleFormRef.value.clearValidate()
  })
}
const editCoures = (item) => {
  ruleForm.value = JSON.parse(JSON.stringify(item))
  if (!ruleForm.value.paper_list) {
    ruleForm.value.paper_list = []
  }
  imgList.value = ruleForm.value.paper_list
  modCoures.value = true
  nextTick(() => {
    ruleFormRef.value.clearValidate()
  })
}
const delCoures = (item) => {
  ElMessageBox.confirm('您确认要删除吗？', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let res = await service.post('/api/patient_paper/delete_patient_paper', {
        id: item.id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
      }
    })
    .catch(() => {})
}

const gitDictVal = (item, val) => {
  if (!dictList.value[item]) return
  let name = ''
  dictList.value[item].map((obj) => {
    if (obj.code == val) {
      name = obj.name
    }
  })
  return name
}

// 文件上传
const handleRequest = async (param) => {
  let fileObj = param.file
  let form = new FormData()
  form.append('img_file', fileObj)
  form.append('type', 'tonglu_photo')
  uploadimg.value.handleRemove(fileObj)
  let res = await service.post('/api/common/upload_img', form)
  if (res.code === 0) {
    let row = {
      type: 1,
      uid: fileObj.uid,
      url:requestUrl + res.data.url,
    }
    imgList.value.push(row)
  }
}

const handleRemove = (file) => {
  ElMessageBox.confirm('您确定要删除此张图片吗?', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      uploadimg.value.handleRemove(file)
      nextTick(() => {
        hideUpload.value = imgList.value.length >= 5
      })
      for (let i = 0; i < imgList.value.length; i++) {
        if (imgList.value[i].uid === file.uid) {
          imgList.value.splice(i, 1)
          return
        }
      }
    })
    .catch(() => {})
}
const handleEditChange = () => {
  hideUpload.value = imgList.value.length >= 5
}

// 保存
const saveLoad = ref(false)
const saveCoures = () => {
  ruleForm.value.patient_id = route.params.id
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      ruleForm.value.paper_list = imgList.value
      saveLoad.value = true
      let url = ''
      if (ruleForm.value.id) {
        url = '/api/patient_paper/edit_patient_paper'
      } else {
        url = '/api/patient_paper/create_patient_paper'
      }
      let res = await service.post(url, ruleForm.value)
      saveLoad.value = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
        modCoures.value = false
      }
    }
  })
}
defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.clerical-item {
  border-bottom: 1px solid #c6c6c6;
  padding: 16px 0;
  ._item {
    margin-right: 4px;
    font-size: 16px;
    ._lable {
      color: #000;
      font-weight: bold;
    }
    .cont {
      color: #333;
    }
  }
  .imgBox {
    ._img {
      width: 100px;
      height: 100px;
      vertical-align: top;
      margin-right: 10px;
    }
  }
}
.schemeBox {
  margin-top: 16px;
  &:deep(.hide .el-upload) {
    display: none;
  }
  .el-form-item {
    width: 45%;
    margin-right: 0;
    &.addr {
      width: 100%;
    }
  }
  .el-form-item:first-child {
    margin-right: 10%;
  }
}
</style>
